@tailwind base;
@tailwind components;
@tailwind utilities;



html{
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: linear-gradient(0.27turn, #d1ffc5, #ffdd1eb8);
  /* background-color: #ff8a58; */
  /* background-repeat: no-repeat;
  width: 100%; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input{
  background-color:  #ffffff80; 
}
input:focus{
  outline: none;
  background-color: #ffffffca;
}
textarea{
  padding-top: 5px;
  background-color:  #ffffff80; 
}
textarea:focus{
  outline: none;
  background-color: #ffffffca;
}

textarea::placeholder{
  color: rgba(46, 46, 46, 0.684);
}
input::placeholder{
  color: rgba(46, 46, 46, 0.684);
}


@media screen and (min-width: 758px) and (max-height: 1000px) {
  .cardsText{
    font-size: 40px !important;
    /* color: white; */
  }
}