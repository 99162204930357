

body{
    overflow-x: hidden;
}

.cards{
    opacity: 0;
    transition: 0.5s ease;
}
.cards{
    opacity: 1;
}

.cormorant{
    font-family: "Cormorant Garamond", serif;;
}
.archivo{
    font-family: "Archivo Narrow", sans-serif;
}